<template>
    <div class="c1 block">
        <div class="col-header">
            <h1>Top {{ thisTeam }}</h1>
            <div class="revenue-today">Week</div>
            <div v-show="nav_toggle === false" class="revenue-week">Vandaag</div>
            <div v-show="nav_toggle === true" class="revenue-week">Kwartaal</div>
        </div>

        <transition-group name="list-partner">
            <div v-for="(item, index) in topPartners" :key="item.partner_id" class="row-partners list-partner-item"
                :class="getRowComplianceClass(item.compliance)">
                <div class="ranking">{{ index + 1 }}.</div>
                <div class="name">{{ item.first_name }} {{ item.last_name }}</div>
                <div class="compliance" :class="getItemComplianceClass(item.compliance)">
                    {{ formatCompliance(item.compliance) }}</div>
                <div class="revenue-today">{{ format(item.cnt_partner_this_week) }}</div>
                <div v-show="nav_toggle === false" class="revenue-week">{{
                    format(item.cnt_partner_today)
                }}
                </div>
                <div v-show="nav_toggle === true" class="revenue-week">
                    {{ format(item.cnt_partner_this_quarter) }}</div>
            </div>
        </transition-group>

    </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import shared from '../shared/shared.js'

export default {
    name: 'TopPartners',
    components: {
    },
    props: {
        certifiedPartnerId: {
            type: String,
            required: false,
        },
        thisTeam: {
            type: String,
            required: false,
            default: 'Improvers'
        },
        mode: {
            type: String,
            required: false,
            default: 'fee'
        },
        toggle: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    setup(props) {
        const { formatFee, getItemComplianceClass, getRowComplianceClass, formatCompliance } = shared()
        const store = useStore();
        const nav_interval = ref(false);
        const nav_toggle = ref(false);

        function toggle() {
            if (nav_interval.value === false) {
                nav_interval.value = setInterval(() => {
                    nav_toggle.value = !nav_toggle.value;
                }, 12000);
            }
        }

        const topPartners = computed(function () {
            if (props.certifiedPartnerId) {
                return store.getters.partnerBig.filter((el) => el.certified_partner_id === props.certifiedPartnerId).slice(0, 24)
            } else {
                return store.getters.partnerBig.slice(0, 24)
            }
        }
        )

        const format = (val) => {
            console.log(props.mode)
            return props.mode === 'fee' ? '€' + formatFee(val) : val;
        }

        onMounted(function () {
            //console.log(store.getters.partnerBig.slice(0, 24))
            console.log(props.thisTeam)
            toggle()
        })

        return { topPartners, format, getItemComplianceClass, getRowComplianceClass, formatCompliance, nav_toggle }
    }
}


</script>